import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

//styles
import * as styles from '../../styles/components/Index/exploreParadise.module.scss';
import { Container, Button } from 'react-bootstrap';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ExploreParadise = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHotels {
          edges {
            node {
              title
              shortDescription {
                shortDescription
              }
              slug
              images {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  height: 450
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    `
  );

  const hotels = data.allContentfulHotels.edges;

  return (
    <>
      <Container className='text-center py-5'>
        <h1 className={styles.title + ' px-1'}>EXPLORE PARADISE</h1>

        <Carousel
          responsive={responsive}
          showDots={false}
          infinite={true}
          autoPlaySpeed={500}
          keyBoardControl={true}
          dotListClass={styles.dots}
          itemClass='carousel-item-padding-40-px my-5'
        >
          {hotels.map(({ node }) => {
            let image = null;
            if (node.images) {
              image = getImage(node.images[0]);
            }
            return (
              <div className={styles.mainDiv}>
                <div className={styles.block}>
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    className={styles.image}
                  />

                  <div className={styles.detailsBox}>
                    <h5>{node.title}</h5>
                    <p>{node.shortDescription.shortDescription}</p>
                  </div>
                  <div className={styles.exploreButton}>
                    <Link to={`/hotels/${node.slug}`}>
                      <Button className='secondary'>Explore</Button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </Container>
    </>
  );
};

export default ExploreParadise;
