import React, { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Carousel from 'react-multi-carousel';

//styles
import * as styles from '../styles/components/Index/welcomeSlider.module.scss';
import 'react-multi-carousel/lib/styles.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const WelcomeSlider = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulExperiences {
          edges {
            node {
              title
              city
              tags
              slug
              images {
                gatsbyImageData(
                  quality: 100
                  width: 400
                  height: 600
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        allContentfulHotels {
          edges {
            node {
              title
              city
              tags
              slug
              images {
                gatsbyImageData(
                  quality: 100
                  width: 400
                  height: 600
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    `
  );

  let carouselRef = useRef(null);

  const experiences = data.allContentfulExperiences.edges;
  const hotels = data.allContentfulHotels.edges;

  return (
    <>
      <Carousel
        ref={(el) => (carouselRef = el)}
        responsive={responsive}
        showDots={false}
        autoPlay={true}
        autoPlaySpeed={5000}
        arrows={false}
        infinite={true}
        keyBoardControl={true}
        // dotListClass={styles.dots}
      >
        {hotels.map(({ node }, idx) => {
          let image = null;
          if (node.images) {
            image = getImage(node.images[0]);
          }

          return (
            <div className='mx-1'>
              <Link key={idx} to={`/hotels/${node.slug}`}>
                {image && (
                  <div className={styles.item}>
                    <GatsbyImage
                      image={image}
                      alt={node.title}
                      className={styles.imgStyle}
                    />
                    <div className={styles.content}>
                      {/* <h3 className={`${styles.subtitle}`}>{node.tags[0]}</h3> */}
                      <p className={`${styles.subtitle} `}>{node.city}</p>
                      <p className={`${styles.title} h2`}>{node.title}</p>
                    </div>
                  </div>
                )}
              </Link>
            </div>
          );
        })}
        {experiences.map(({ node }, idx) => {
          let image = null;
          if (node.images) {
            image = getImage(node.images[0]);
          }

          return (
            <div className='mx-1'>
              <Link key={idx} to={`/experiences/${node.slug}`}>
                {image && (
                  <div className={styles.item}>
                    <GatsbyImage
                      image={image}
                      alt={node.title}
                      className={styles.imgStyle}
                    />
                    <div className={styles.content}>
                      {/* <h3 className={`${styles.subtitle}`}>{node.tags[0]}</h3> */}
                      <p className={`${styles.subtitle} `}>{node.city}</p>
                      <p className={`${styles.title} h2`}>{node.title}</p>
                    </div>
                  </div>
                )}
              </Link>
            </div>
          );
        })}
      </Carousel>

      <Row className='ps-3 mt-4'>
        <Col className='d-flex align-items-center'>
          <button
            className={styles.button}
            onClick={() => {
              carouselRef.previous();
            }}
          >
            <FaArrowLeft />
          </button>

          <button
            className={styles.button}
            onClick={() => {
              carouselRef.next();
            }}
          >
            <FaArrowRight />
          </button>
          <span className='text-white border-top w-100 border-2 mx-3' />
        </Col>
      </Row>
    </>
  );
};

export default WelcomeSlider;
