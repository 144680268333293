import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

//styles
import * as styles from '../../styles/components/Index/hosts.module.scss';

const Hosts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHosts {
          edges {
            node {
              name
              priority
              position
              slug
              description {
                description
              }
              images {
                gatsbyImageData(
                  quality: 100
                  width: 400
                  height: 600
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    `
  );

  const hosts = data.allContentfulHosts.edges
    .sort((a, b) => {
      if (
        a.node.position.toLowerCase().includes('senior') &&
        a.node.position.toLowerCase().includes('manager')
      ) {
        return 1;
      }
    })
    .sort((a, b) => {
      if (a.node.position.toLowerCase().includes('manager')) {
        return -1;
      }
    })
    .sort((a, b) => {
      if (a.node.position.toLowerCase().includes('director')) {
        return -1;
      }
    });

  return (
    <Container className='my-5 py-2 py-md-5'>
      <h1 className={`${styles.titleMain}`}>Meet Your Hosts</h1>
      <Row className='d-flex justify-center flex-wrap'>
        {hosts
          .sort((a, b) => a.node.priority - b.node.priority)
          .map(({ node }, idx) => {
            let image = null;
            if (node.images) {
              image = getImage(node.images);
            }
            return (
              <Card className={styles.cardMain} key={idx}>
                <GatsbyImage
                  image={image}
                  alt={node.name}
                  className={styles.image}
                />

                <Card.Body className='text-center'>
                  <Card.Title>
                    <h6 className={styles.title}>{node.name}</h6>
                  </Card.Title>
                  <Card.Text>
                    <p className={styles.subtitle}>{node.position}</p>
                  </Card.Text>
                </Card.Body>
                <div className={styles.details}>
                  <h6 className={styles.detailsTitle}>{node.name}</h6>
                  <p className={styles.detailsSubTitle}>{node.position}</p>
                  <p className={styles.detailsSummary}>
                    {node.description?.description}
                  </p>
                </div>
              </Card>
            );
          })}
      </Row>
    </Container>
  );
};

export default Hosts;
