import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import 'react-multi-carousel/lib/styles.css';

//styles
import * as styles from '../../styles/components/Index/gallery.module.scss';
import { Container, Row, Col } from 'react-bootstrap';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Gallery = () => {
  const [filter, setFilter] = useState('All');

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulLocations {
          edges {
            node {
              locationName
              __typename
              tags
              slug
              title
              images {
                gatsbyImageData(quality: 100, width: 400, layout: FULL_WIDTH)
              }
            }
          }
        }
        allContentfulExperiences {
          edges {
            node {
              __typename

              tags
              slug
              title
              city
              images {
                gatsbyImageData(quality: 100, width: 400, layout: FULL_WIDTH)
              }
            }
          }
        }
        allContentfulHotels {
          edges {
            node {
              __typename
              tags
              slug
              title
              city
              images {
                gatsbyImageData(quality: 100, width: 400, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );

  const locations = data.allContentfulLocations.edges;
  const experiences = data.allContentfulExperiences.edges;
  const hotels = data.allContentfulHotels.edges;

  const filters = [
    'All',
    'Cultural',
    'Mountains',
    'Beach',
    'Wilderness',
    'Adventure',
  ];

  const slides = [...experiences].filter(({ node }) => {
    if (filter === 'All') {
      return true;
    }
    const containsFilter = node.tags?.includes(filter);
    return containsFilter;
  });

  const handleSetFilter = (title) => {
    setFilter(title);
  };

  return (
    <>
      <Container fluid>
        <Row className='align-items-center'>
          <Col sm={12} lg={4} xl={3}>
            <Container className='px-4 justify-content-center align-items-center  text-lg-start'>
              <h1 className={styles.title}>A Host Of Adventures Await</h1>

              <div
                className={`d-flex flex-lg-column flex-wrap justify-content-center mt-lg-4 mx-md-5 ${styles.filters}`}
              >
                <div
                  className={`${styles.selector}  d-none d-lg-block`}
                  style={{
                    top: `calc(16.66% * ${filters.indexOf(filter)} + 2%)`,
                  }}
                />

                {/* Filters by Tag */}
                {filters.map((title, index) => {
                  return (
                    <div key={index}>
                      <p
                        role='button'
                        value={title}
                        onClick={() => {
                          handleSetFilter(title);
                        }}
                        className={`${styles.link} bg-white px-2 p-lg-0 my-2 ${
                          title.toLowerCase() === filter.toLowerCase() &&
                          'text-primary'
                        }`}
                      >
                        {title}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </Col>

          <Col sm={12} lg={8} xl={9} className='p-0 '>
            <Row xs={2} sm={2} lg={3} className='g-0 mx-md-5'>
              {slides.splice(0, 6).map(({ node }, idx) => {
                let linkString = '/experiences/';

                if (node.__typename.toLowerCase().includes('locations')) {
                  linkString = '/locations/';
                } else if (node.__typename.toLowerCase().includes('hotel')) {
                  linkString = '/hotels/';
                }

                let image = null;
                if (node.images) {
                  image = getImage(
                    node.images[Math.floor(Math.random() * node.images.length)]
                  );
                }

                return (
                  <Col className={` p-2 ${styles.imageCover}`} key={idx}>
                    <Link to={linkString + node.slug}>
                      <div className={styles.imageWrapper}>
                        <GatsbyImage
                          image={image}
                          alt={node.locationName}
                          className={styles.image}
                        />

                        <div className={styles.content}>
                          {/* <h3 className={`${styles.subtitle}`}>{node.tags[0]}</h3> */}
                          <p className={`${styles.subtitle} `}>{node.city}</p>
                          <p className={`${styles.title2} h2`}>{node.title}</p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Gallery;
