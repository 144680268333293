import React from "react";
import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import Carousel from "react-multi-carousel";

//styles

import * as styles from "../../styles/components/Index/testimonials.module.scss";


const Testimonials = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulReviews {
          edges {
            node {
              title
              name
              statement {
                statement
              }
            }
          }
        }
      }
    `
  );

  const reviews = data.allContentfulReviews.edges;

  return (
    <div
      className={`${styles.backgroundimg} d-flex justify-content-start align-items-center`}
    >
      <Container fluid className={styles.carousel}>
        <Carousel
          className={styles.carouselContainer}
          additionalTransfrom={0}
          showDots
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
          shouldResetAutoplay
          slidesToSlide={1}
          swipeable
        >
          {reviews.map(({ node }, idx) => (
            <div className={"text-center p-md-5"}>
              <div className={styles.card}>
                <h3>“{node.title}”</h3>
                <p className="mt-3">{node.statement.statement}</p>
                <h5>{node.name}</h5>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Testimonials;
