import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Intro from '../components/Index/intro';
import Welcome from '../components/Index/welcome';
import ExploreParadise from '../components/Index/exploreParadise';
import Gallery from '../components/Index/gallery';
import Hosts from '../components/Index/hosts';
import Testimonials from '../components/Index/Testimonials';
import Popup from '../components/popup';
import Reps from '../components/Index/reps';

const IndexPage = () => (
  <Layout>
    <SEO title=' &zwnj; ' />
    {/* <Popup /> */}
    <Welcome />
    <Intro />
    <ExploreParadise />
    <Gallery />
    <Hosts />
    <Reps />
    <Testimonials />
  </Layout>
);

export default IndexPage;
