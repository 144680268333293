import React from 'react';

import Title from '../Index/title';
import { Container, Col, Row } from 'react-bootstrap';
import * as styles from '../../styles/components/Index/welcome.module.scss';
import SocialMedialLinks from '../socialMedialLinks';
import WelcomeSlider from '../welcomeSlider';
import video from '../../images/trip-fusion.mp4';

const Welcome = () => {
  return (
    <div className={styles.welcomeDiv}>
      <video
        muted
        autoPlay
        className={`${styles.video} ratio ratio-16x9`}
        style={{ objectFit: 'cover' }}
        width='100vw'
        height='50vh'
        loop
      >
        <source src={video} type='video/mp4' className=' ratio ratio-16x9' />
      </video>

      <Container fluid style={{ height: '100vh' }} className='px-5'>
        <Row className={styles.main}>
          <Col lg={2} className='d-none d-lg-block position-relative mt-auto'>
            <div className={`text-white ${styles.rotate}`}>
              {/* <p className='m-0 small'>
                Authentic Spiritual Wellbeing | Bespoke Luxury Travel
                Experiences
              </p> */}
            </div>
          </Col>

          <Col sm={12} lg={4}>
            <Title
              first='EXPLORE'
              second='SRI LANKA'
              sup='Experience the majestic beauty and culture of Ceylon'
            />
            <h3 className='text-light d-none d-md-block'>
              <SocialMedialLinks />
            </h3>
          </Col>
          <Col sm={12} lg={6}>
            <WelcomeSlider />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Welcome;
