import React from "react";

import * as styles from "../../styles/components/Index/title.module.scss";

const Title = ({ first, second, sup }) => {
  return (
    <div className={`${styles.title} mb-3 mb-md-0 text-3xl`}>
      <h1 className={`${styles.main} display-1`}>
        {first} <br className="d-none d-lg-block" /> {second}
      </h1>
      <p className={styles.sup}>{sup}</p>
    </div>
  );
};

export default Title;
